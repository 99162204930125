import get from 'lodash/get';
import { toCreditSnapEmploymentType } from '../../utilities/toCreditSnapEmploymentType';
import { toCreditSnapIncomeType } from '../../utilities/toCreditSnapIncomeType';
import { creditSnapOwnershipStatusMap, MONTHLY } from '../../values/application';

export const creditSnapApplication = (state, getters, rootState, rootGetters) => {
  const primaryApplicantCurrentAddress = rootGetters['residence/primaryApplicantCurrentAddress'];

  const primaryApplicantData = {
    applicantType: 'P', // stands for PRIMARY
    firstName: rootGetters['applicant/primaryApplicantFirstName'],
    lastName: rootGetters['applicant/primaryApplicantLastName'],
    dob: rootGetters['applicant/primaryApplicantDateOfBirth'],
    ssn: rootGetters['applicant/primaryApplicantSocialSecurityNumber'],
    email: rootGetters['applicant/primaryApplicantEmailAddress'],
    primaryPhone: rootGetters['applicant/primaryApplicantMobileNumber'],
    militaryActiveDutyIndicator: rootGetters['employment/primaryApplicantCurrentEmploymentIsMilitary'],

    address: {
      streetAddress: get(primaryApplicantCurrentAddress, 'street'),
      city: get(primaryApplicantCurrentAddress, 'city'),
      state: get(primaryApplicantCurrentAddress, 'state'),
      zipCode: get(primaryApplicantCurrentAddress, 'zipCode')
    },

    employers: [{
      empName: rootGetters['employment/primaryApplicantCurrentEmploymentEmployerName'],
      empStatus: 'C', // Always current
      empType: toCreditSnapEmploymentType(
        rootGetters['employment/primaryApplicantCurrentEmploymentTypeValue']
      ),
      salary: rootGetters['employment/primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber'],
      schedule: 'ANNUALLY', // Always annually
      length: rootGetters['employment/primaryApplicantTotalNumberOfMonthsAtCurrentEmployment'],
      position: rootGetters['employment/primaryApplicantCurrentEmploymentJobTitle']
    }],

    residence: {
      status:
      creditSnapOwnershipStatusMap[rootGetters['residence/primaryApplicantCurrentResidenceType']] || null,
      cost: rootGetters['residence/primaryApplicantMonthlyPaymentAtCurrentResidenceAsNumber'],
      schedule: 'MONTHLY', // Always monthly
      length: rootGetters['residence/primaryApplicantTotalNumberOfMonthsAtCurrentResidence']
    }
  };

  if (rootGetters['employment/primaryApplicantHasAdditionalIncome']) {
    primaryApplicantData.additionalIncomes = [{
      incomeType: toCreditSnapIncomeType(
        rootGetters['employment/primaryApplicantAdditionalIncomeSource']
      ),
      amount: rootGetters['employment/primaryApplicantAdditionalIncomeAmountPerMonthAsNumber'],
      schedule: MONTHLY
    }];
  }

  if (rootGetters['residence/primaryApplicantHasResidedAtCurrentResidenceLessThanTwoYears']) {
    const primaryApplicantPreviousAddress = rootGetters['residence/primaryApplicantPreviousAddress'];

    primaryApplicantData.pastResidenceDetails = [{
      status: null, // currently, we do not collect this information
      length: rootGetters['residence/primaryApplicantTotalNumberOfMonthsAtPreviousResidence'],
      address: {
        streetAddress: get(primaryApplicantPreviousAddress, 'street'),
        city: get(primaryApplicantPreviousAddress, 'city'),
        state: get(primaryApplicantPreviousAddress, 'state'),
        zipCode: get(primaryApplicantPreviousAddress, 'zipCode')
      }
    }];
  }

  if (rootGetters['employment/primaryApplicantHasPreviousEmploymentType']) {
    primaryApplicantData.employers.push({
      empName: rootGetters['employment/primaryApplicantPreviousEmploymentEmployerName'],
      empStatus: 'P', // Always prior
      empType: toCreditSnapEmploymentType(
        rootGetters['employment/primaryApplicantPreviousEmploymentTypeValue']
      ),
      salary: null, // currently, we do not collect this information
      schedule: 'ANNUALLY', // Always annually
      length: rootGetters['employment/primaryApplicantTotalNumberOfMonthsAtPreviousEmployment'],
      position: rootGetters['employment/primaryApplicantPreviousEmploymentJobTitle']
    });
  }

  const payload = {
    applicants: [primaryApplicantData],
    applicationType: rootGetters['applicant/hasJointApplicant'] ? 'J' : 'I',
    referralCode: 'creditkarma',
    productType: 'AUTO',
    loanPurpose: 'R', // Always Refinance
    // SoftInquiry is required by the API, but we intend to run a HardInquiry, so both are included
    consent: [
      { name: 'SoftInquiry', value: 'Y' },
      { name: 'HardInquiry', value: 'Y' }
    ],
    collateral: {
      vehicleType: 'Auto', // always auto in FT
      year: rootGetters['vehicle/year'],
      make: rootGetters['vehicle/makeName'],
      model: rootGetters['vehicle/modelName'],
      body: rootGetters['vehicle/trimName'],
      mileage: rootGetters['vehicle/mileageAsNumber'],
      autoType: 'USED',
      loanAmount: rootGetters['vehicle/payoffAmountAsNumber']
    }
  };

  if (rootGetters['applicant/hasJointApplicant']) {
    const jointApplicantCurrentAddress = rootGetters['residence/jointApplicantCurrentAddress'];

    const jointApplicantData = {
      applicantType: 'S', // stands for SECONDARY
      firstName: rootGetters['applicant/jointApplicantFirstName'],
      lastName: rootGetters['applicant/jointApplicantLastName'],
      dob: rootGetters['applicant/jointApplicantDateOfBirth'],
      ssn: rootGetters['applicant/jointApplicantSocialSecurityNumber'],
      email: rootGetters['applicant/jointApplicantEmailAddress'],
      primaryPhone: rootGetters['applicant/jointApplicantMobileNumber'],
      militaryActiveDutyIndicator: rootGetters['employment/jointApplicantCurrentEmploymentIsMilitary'],

      address: {
        streetAddress: get(jointApplicantCurrentAddress, 'street'),
        city: get(jointApplicantCurrentAddress, 'city'),
        state: get(jointApplicantCurrentAddress, 'state'),
        zipCode: get(jointApplicantCurrentAddress, 'zipCode')
      },

      employers: [{
        empName: rootGetters['employment/jointApplicantCurrentEmploymentEmployerName'],
        empStatus: 'C', // Always current
        empType: toCreditSnapEmploymentType(
          rootGetters['employment/jointApplicantCurrentEmploymentTypeValue']
        ),
        salary: rootGetters['employment/jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber'],
        schedule: 'ANNUALLY', // Always annual
        length: rootGetters['employment/jointApplicantTotalNumberOfMonthsAtCurrentEmployment'],
        position: rootGetters['employment/jointApplicantCurrentEmploymentJobTitle']
      }],

      residence: {
        status: creditSnapOwnershipStatusMap[rootGetters['residence/jointApplicantCurrentResidenceType']] || null,
        cost: rootGetters['residence/jointApplicantMonthlyPaymentAtCurrentResidenceAsNumber'],
        schedule: 'MONTHLY', // Always monthly
        length: rootGetters['residence/jointApplicantTotalNumberOfMonthsAtCurrentResidence']
      }
    };

    if (rootGetters['employment/jointApplicantHasPreviousEmploymentType']) {
      jointApplicantData.employers.push({
        empName: rootGetters['employment/jointApplicantPreviousEmploymentEmployerName'],
        empStatus: 'P', // Always prior
        empType: toCreditSnapEmploymentType(
          rootGetters['employment/jointApplicantPreviousEmploymentTypeValue']
        ),
        salary: null, // currently, we do not collect this information
        schedule: 'ANNUALLY', // Always annual
        length: rootGetters['employment/jointApplicantTotalNumberOfMonthsAtPreviousEmployment'],
        position: rootGetters['employment/jointApplicantPreviousEmploymentJobTitle']
      });
    }

    if (rootGetters['employment/jointApplicantHasAdditionalIncome']) {
      jointApplicantData.additionalIncomes = [{
        incomeType: toCreditSnapIncomeType(
          rootGetters['employment/jointApplicantAdditionalIncomeSource']
        ),
        amount: rootGetters['employment/jointApplicantAdditionalIncomeAmountPerMonthAsNumber'],
        schedule: MONTHLY
      }];
    }

    payload.applicants.push(jointApplicantData);
  }

  return payload;
};
