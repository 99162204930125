/**
 * Application value constants.
 *
 *
 */
import zipObject from 'lodash/zipObject';
import {
  DEFAULT_BRAND_LEAD_CHANNEL,
  RATEGENIUS_LEAD_CHANNEL,
  TRESL_LEAD_CHANNEL
} from '../values/brand';

export const YES = 'YES';
export const NO = 'NO';
export const NOT_APPLICABLE = 'N/A';
export const LIVES_WITH_RELATIVE = 'LIVES_WITH_RELATIVE';
export const RENT = 'RENT';
export const OWN = 'OWN';
export const REFINANCE = 'REFINANCE';
export const LEASE_BUYOUT = 'LEASE_BUYOUT';
export const PURCHASE = 'PURCHASE';
export const ENGLISH = 'ENGLISH';
export const FULL_TIME = 'FULL TIME';
export const PART_TIME = 'PART TIME';
export const MILITARY = 'MILITARY';
export const SELF_EMPLOYED = 'SELF_EMPLOYED';
export const STUDENT = 'STUDENT';
export const HOMEMAKER = 'HOMEMAKER';
export const RETIRED = 'RETIRED';
export const UNEMPLOYED = 'UNEMPLOYED';
export const DISABILITY = 'DISABILITY';
export const OTHER = 'OTHER';
export const JOINT = 'JOINT';
export const SINGLE = 'SINGLE';
export const SHOP_RATES = 'Browsing';
export const LOWER_PAYMENT = 'Lower monthly payment';
export const LOWER_RATE = 'Lower interest rate';
export const TITLE_REMOVE = 'Remove someone from title';
export const CASH_OUT = 'Cash-out refi';
export const BUY_FROM_DEALERSHIP = 'Buying from a dealership';
export const DEALER = 'DEALER';
export const BUY_FROM_PRIVATE_SELLER = 'Buying from a private seller';
export const PRIVATE_PARTY = 'PRIVATE_PARTY';
export const SPOUSE = 'SPOUSE';
export const PARENT = 'PARENT';
export const CHILD = 'CHILD';
export const RELATIVE = 'RELATIVE';
export const COHABITANT = 'COHABITANT';
export const ACTIVE_MILITARY = 'ACTIVE_MILITARY';
export const RELATIVE_OWNED = 'RELATIVE_OWNED';
export const FRIEND = 'FRIEND';
export const BI_WEEKLY = 'BI_WEEKLY';
export const MONTHLY = 'MONTHLY';
export const YEARLY = 'YEARLY';

export const DEFAULT_APPLICATION_TYPE = REFINANCE;

export const validApplicationTypes = [
  REFINANCE,
  LEASE_BUYOUT,
  PURCHASE
];

export const purchaseApplicationTypes = [
  PURCHASE,
  DEALER,
  PRIVATE_PARTY
];

export const applicantTypes = {
  JOINT,
  SINGLE
};

export const options = {
  YES,
  NO,
  NOT_APPLICABLE
};

export const loanTypes = {
  REFINANCE
};

export const langPref = {
  ENGLISH
};

export const residenceTypeLabels = [
  { label: 'Own', value: OWN },
  { label: 'Rent', value: RENT },
  { label: 'Lives with relative', value: LIVES_WITH_RELATIVE }
];

export const relationshipTypeLabels = [
  { label: 'Spouse', value: SPOUSE },
  { label: 'Parent', value: PARENT },
  { label: 'Child', value: CHILD },
  { label: 'Relative', value: RELATIVE },
  { label: 'Cohabitant', value: COHABITANT },
  { label: 'Other', value: OTHER }
];

export const autopayRelationshipType = {
  SPOUSE,
  PARENT: RELATIVE,
  CHILD: RELATIVE,
  RELATIVE,
  COHABITANT: FRIEND,
  OTHER: FRIEND
};

export const customerIntent = {
  SHOP_RATES,
  LOWER_PAYMENT,
  LOWER_RATE,
  TITLE_REMOVE,
  CASH_OUT,
  BUY_FROM_DEALERSHIP,
  BUY_FROM_PRIVATE_SELLER
};

export const jointRelationTypes = {
  SPOUSE,
  PARENT,
  CHILD,
  RELATIVE,
  COHABITANT,
  OTHER
};

export const autopayApplicationTypeMap = {
  [SHOP_RATES]: REFINANCE,
  [LOWER_PAYMENT]: REFINANCE,
  [LOWER_RATE]: REFINANCE,
  [TITLE_REMOVE]: REFINANCE,
  [PURCHASE]: PURCHASE,
  [BUY_FROM_DEALERSHIP]: DEALER,
  [BUY_FROM_PRIVATE_SELLER]: PRIVATE_PARTY
};

// keeping this local for now to try to avoid
// confusion with `UNEMPLOYED` value...
const UN_EMPLOYED = 'UN_EMPLOYED';

export const autopayEmploymentTypeMap = {
  [HOMEMAKER]: UN_EMPLOYED,
  // constant value is 'FULL TIME' (space delimited, not underscore)
  [FULL_TIME]: 'FULL_TIME',
  [MILITARY]: ACTIVE_MILITARY,
  [OTHER]: UN_EMPLOYED,
  // constant value is 'PART TIME' (space delimited, not underscore)
  [PART_TIME]: 'PART_TIME',
  [RETIRED]: RETIRED,
  [SELF_EMPLOYED]: SELF_EMPLOYED,
  [STUDENT]: UN_EMPLOYED,
  [UNEMPLOYED]: UN_EMPLOYED
};

export const consumerPortalEmploymentTypeMap = {
  [HOMEMAKER]: HOMEMAKER,
  // constant value is 'FULL TIME' (space delimited, not underscore)
  [FULL_TIME]: 'FULL_TIME',
  [MILITARY]: ACTIVE_MILITARY,
  [OTHER]: OTHER,
  // constant value is 'PART TIME' (space delimited, not underscore)
  [PART_TIME]: 'PART_TIME',
  [RETIRED]: RETIRED,
  [SELF_EMPLOYED]: SELF_EMPLOYED,
  [STUDENT]: STUDENT,
  [UNEMPLOYED]: UN_EMPLOYED
};

export const autopayOwnershipStatusMap = {
  [LIVES_WITH_RELATIVE]: RELATIVE_OWNED,
  [OWN]: OWN,
  [RENT]: RENT
};

export const vehicleDetailProviders = {
  CHROMEDATA: 'CHROMEDATA',
  KBB: 'KBB',
  NADA: 'NADA'
};

export const primaryApplicantAdditionalIncomeDropdownValues = [
  { label: 'I do not have additional income', value: false },
  { label: 'I do have additional income', value: true }
];

export const jointApplicantAdditionalIncomeDropdownValues = [
  { label: 'Co-borrower does not have additional income', value: false },
  { label: 'Co-borrower has additional income', value: true }
];

export const additionalIncomeSourceDropdownValues = [
  'Disability',
  'Social security',
  'Child support',
  'Public assistance',
  'Aid for developing children',
  'Cost of living allowance (COLA)',
  'Military basic allowance for subsistence (BAS)',
  'Municipal bond interest',
  'Second employer',
  "Workman's compensation",
  'Non taxable sources of income',
  'Other'
];

export const additionalIncomeFrequencyDropdownValues = [
  { label: 'Bi-weekly', value: BI_WEEKLY },
  { label: 'Monthly', value: MONTHLY },
  { label: 'Yearly', value: YEARLY }
];

export const creditSnapEmploymentTypeMap = {
  [HOMEMAKER]: 'N',
  // constant value is 'FULL TIME' (space delimited, not underscore)
  [FULL_TIME]: 'F',
  [MILITARY]: 'M',
  [OTHER]: 'N',
  // constant value is 'PART TIME' (space delimited, not underscore)
  [PART_TIME]: 'P',
  [RETIRED]: 'R',
  [SELF_EMPLOYED]: 'S',
  [STUDENT]: 'N',
  [UNEMPLOYED]: 'N'
};

export const creditSnapOwnershipStatusMap = {
  [LIVES_WITH_RELATIVE]: OWN,
  [OTHER]: OWN,
  [OWN]: OWN,
  [RENT]: RENT
};

export const employerFieldNaList = [
  STUDENT,
  HOMEMAKER,
  RETIRED,
  UNEMPLOYED,
  DISABILITY
];

// the index in this list should match the index
// of the additionalIncomeSourceDropdownValues list
export const creditSnapIncomeTypeList = [
  'O',
  'S',
  'CS',
  'S',
  'CS',
  'O',
  'O',
  'O',
  'AI',
  'S',
  'AI',
  'O'
];

// the index in this list should match the index
// of the additionalIncomeSourceDropdownValues list
export const consumerPortalIncomeSourceList = [
  'DISABILITY',
  'SOCIAL_SECURITY',
  'CHILD_SUPPORT',
  'PUBLIC_ASSISTANCE',
  'AID_FOR_DEPENDENT_CHILDREN',
  'COST_OF_LIVING_ALLOWANCE',
  'MILITARY_BASIC_ALLOWANCE_FOR_SUBSISTENCE',
  'MUNICIPAL_BOND_INTEREST',
  'SECONDARY_EMPLOYMENT',
  'WORKMANS_COMPENSATION',
  'NON_TAXABLE_SOURCES',
  'OTHER'
];

// Assemble the mapping object so the exported value looks like this:
// {
//   "index 0 value from additionalIncomeSourceDropdownValues": "index 0 value from creditSnapIncomeTypeList",
//   "index 1 value from additionalIncomeSourceDropdownValues": "index 1 value from creditSnapIncomeTypeList"
//   ...
// }
export const creditSnapIncomeTypeMap = zipObject(
  additionalIncomeSourceDropdownValues,
  creditSnapIncomeTypeList
);

export const consumerPortalIncomeSourceMap = zipObject(
  additionalIncomeSourceDropdownValues,
  consumerPortalIncomeSourceList
);

export const AUTOPAY_TEST_URLS = [
  ['autopay-LOCAL', 'https://local-apply.autopay.com', DEFAULT_BRAND_LEAD_CHANNEL],
  ['autopay-DEV', 'https://dev-apply.autopay.com', DEFAULT_BRAND_LEAD_CHANNEL],
  ['autopay-STAGE', 'https://stage-apply.autopay.com', DEFAULT_BRAND_LEAD_CHANNEL],
  ['autopay-PROD', 'https://apply.autopay.com', DEFAULT_BRAND_LEAD_CHANNEL],
  ['tresl-DEV', 'https://dev-fastrack.mytresl.com', TRESL_LEAD_CHANNEL],
  ['tresl-PROD', 'https://fastrack.mytresl.com', TRESL_LEAD_CHANNEL],
  ['rategenius-DEV', 'https://dev-apply.savewithrategenius.com', RATEGENIUS_LEAD_CHANNEL],
  ['rategenius-STAGE', 'https://stage-apply.rategenius.com', RATEGENIUS_LEAD_CHANNEL],
  ['rategenius-PROD', 'https://apply.savewithrategenius.com', RATEGENIUS_LEAD_CHANNEL]
];
